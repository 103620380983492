<template>
  <div class="footer">
    <div class="footer-item">
      <span
        v-for="(item, index) in list"
        :key="index"
        class="footer-item-text"
        @click="gourl(item)"
        >{{ item.name }} <span v-if="index !== list.length - 1">|</span></span
      >
      <div class="copyright">
        Copyright © 2023如有版权问题请联系删除 <span>|</span>
        <span @click="goBtn">新ICP备20000623号-2</span
        ><span style="margin: 0 5px">|</span> <span></span>
       
        
      </div>

    
      
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      list: [
        { name: "加入我们", url: "" },
        {
          name: "联系电话：0991-4817701",
          url: "",
        },
        { name: "关于我们", url: "/aboutUs" },
        { name: "服务协议", url: "/service" },
        { name: "隐私政策", url: "privacy" },
        { name: "帮助与反馈", url: "/help" },
        { name: "更新日志", url: "/updatelog" },
      ],
    };
  },
  methods: {
    gourl(val) {
      this.$router.push(val.url);
    },
    goBtn() {
      let path = "https://beian.miit.gov.cn";
      window.open(path, "_blank");
    },
    goPermit() {
      let path =
        "https://admin.xjzvt.com/uploads/20230809/83eca26e2cfa2200d2a717b9c29a3d0d.jpg";
      window.open(path, "_blank");
    },
    filings(val) {
      if (val == 1) {
        let path =
          "http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=50010302005026";
        window.open(path, "_blank");
      } else {
        let path =
          "http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=50010302005009";
        window.open(path, "_blank");
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.footer {
  height: 100%;
  background: #1f2833;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
    z-index: 999;


  .footer-item {
   
    width: 1200px;
    color: #7b8a95;
    font-size: 14px;
    position: relative;
    .copyright {
      margin-top: 20px;
      cursor: pointer;
      display: flex;
      align-items: center;
      img {
        height: 15px;
        width: 15px;
        margin-right: 5px;
      }
    }

    .footer-item-text {
      cursor: pointer;
      padding-left: 6px;
    }
  }
}
</style>