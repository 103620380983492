export const scRouter = [
    {path: "/", redirect: '/index'},
    {

       
        meta:{
            title: "学教在线",
            
    
            },
        path: "/index",
        redirect: '',
        component: () => import("../views/home/index.vue"),
    },
   
 
  
    {
        meta:{
        title: "全部直播",
        

        },
        path: '/allLiveStreaming',
        component: () => import("../views/allLiveStreaming/index.vue"),

    },
    {
        meta:{
            title: "全部直播详情",

        },
        path: '/allLiveStreaming/details',
        component: () => import("../views/allLiveStreaming/details"),

    },
    
    {
        
        meta:{
            title: "关于我们",
        },
        path: '/aboutUs',
        component: () => import("../views/aboutUs/index.vue"),

    },
    {
      
        meta:{
            title: '企业服务',
        },
        path: '/enterpriseCulture',
        component: () => import("../views/enterpriseCulture/index.vue"),

    },
    {
        
        meta:{
            title: '实名认证详情',
        },
        path: '/myAutonymDetail',
        component: () => import("../views/personalCenter/myAutonym/detail.vue"),

    },
    {
        title: '个人中心',
        path: '/personalCenter',
        component: () => import("../views/personalCenter/index.vue"),
        redirect: '/personalCenter/goods',
        children: [
            {
                
                path: 'myCourse',
                component: () => import("../views/personalCenter/myCourse/index.vue"),
                meta:{
                    title: '我的课程',
                }

            },
            {
                meta:{
                    title: '我的练习',
                },
                path: 'myExercise',
                component: () => import("../views/personalCenter/myExercise/index.vue"),

            }, {
                
                meta:{
                    title: '我的考试',
                },
                path: 'myExam',
                component: () => import("../views/personalCenter/myExam/index.vue"),

            }, {
                meta:{
                    title: '我的证书',
                },
                path: 'myCertificate',
                component: () => import("../views/personalCenter/myCertificate/index.vue"),

            }, {
              
                meta:{
                    title: '我的实名',
                },
                path: 'myAutonym',
                component: () => import("../views/personalCenter/myAutonym/index.vue"),

            },
            {
              
                meta:{
                    title: '我的班级',
                },
                path: 'class',
                component: () => import("../views/personalCenter/meineClass/index.vue"),

            },
            {
              
                meta:{
                    title: '我的订单',
                },
                path: 'goods',
                component: () => import("../views/personalCenter/goods/index.vue"),

            },
            {
              
                meta:{
                    title: '我的兑换',
                },
                path: 'exchange',
                component: () => import("../views/personalCenter/exchange/index.vue"),

            },
            {
              
                meta:{
                    title: '我的发票',
                },
                path: 'myInvoice',
                component: () => import("../views/personalCenter/myInvoice/index.vue"),

            },
            {
              
                meta:{
                    title: '修改密码',
                },
                path: 'modifypwas',
                component: () => import("../views/personalCenter/modifypwas/index.vue"),

            }
        ]

    },
    {
        
        meta:{
            title: '试卷报告',
        },
        path: '/examReport',
        component: () => import("../views/personalCenter/examReport")
    },
    {
        
        meta:{
            title: '订单支付',
        },
        path: '/payment',
        component: () => import("../components/page/payment.vue")
    },
    {
        
        meta:{
            title: '更新日志',
        },
        path: '/updatelog',
        component: () => import("../components/footer/updatelog.vue")
    },
    {
        
        meta:{
            title: '帮助与反馈',
        },
        path: '/help',
        component: () => import("../components/footer/help.vue")
    },
    {
        
        meta:{
            title: '隐私政策',
        },
        path: '/privacy',
        component: () => import("../components/footer/privacy.vue")
    },
    {
        
        meta:{
            title: '服务协议',
        },
        path: '/service',
        component: () => import("../components/footer/service.vue")
    },


]