<template>
  <div class="login">
    <!-- 登录 -->
    <div class="login-content" v-if="pasw_show == 1">
      <div class="login-content-title">欢迎登录</div>
      <span
        class="forgot-password"
        style="margin-left: 5px"
        @click="$store.commit('CHANGEPAW', 3)"
        >忘记密码?</span
      >
      <el-input
        v-model="login_data.account"
        placeholder="请输入您的身份证号/手机号"
        @input="accountChange"
      ></el-input>
      <div class="login-password">
        <el-input
          type="password"
          v-model="login_data.password"
          placeholder="请输入您密码"
          @input="loginChange"
        ></el-input>
      </div>

      <el-button type="primary" @click="loginBtn" :disabled="login_disabled"
        >登录</el-button
      >
      <div class="login-content-footer">
        没有账号,
        <span class="registrieren" @click="$store.commit('CHANGEPAW', 2)">立即注册</span>
      </div>
      <i class="el-icon-close" @click="$emit('close', false)"></i>
      <div class="code-box" v-show="code_show">
        
        <div class="title"><span>拖动下方滑块完成验证</span>   </div>
        <dragVerifyImg
          :width="380"
          :height="45"
          ref="dragVerify"
          :imgsrc="imgsrc"
          :isPassing.sync="isPassing"
          :showRefresh="true"
          text="请按住滑块拖动"
          successText="验证通过"
          handlerIcon="el-icon-d-arrow-right"
          successIcon="el-icon-circle-check"
          @passcallback="passcallback"
        >
        </dragVerifyImg>
        <div class="footer-refresh"><i @click="refresh" class=" el-icon-refresh-right"></i> <i class="el-icon-circle-close" @click="code_show=false"></i></div>
      </div>
    </div>
    <!-- 注册 -->
    <div class="register-content" v-if="pasw_show==2">
      <div class="login-content-title">欢迎注册</div>
      <el-input
        v-model="register_data.nickname"
        placeholder="请输入您的姓名"
      ></el-input
      ><el-input
        v-model="register_data.username"
        placeholder="请输入您的身份证号"
      ></el-input>
      <el-input
        v-model="register_data.mobile"
        placeholder="请输入您的手机号"
      ></el-input>
      <el-input
        v-model="register_data.company_name"
        placeholder="请输入您的所属单位"
      ></el-input>
      <div class="password-input">
        <el-input
          type="password"
          v-model="register_data.password"
          placeholder="请输入密码"
        ></el-input>
        <span class="password-tishi"
          >密码必须包含字母(区分大小写),数字,特殊符号(@#$%^&+=!)</span
        >
      </div>
      <div>
        <el-input
          type="password"
          v-model="two_password"
          placeholder="请再次输入密码"
        ></el-input>
      </div>

      <el-button type="primary" @click="registerBtn">注册</el-button>
      <div class="register-content-footer">
        已有账号？
        <span class="registrieren" @click="$store.commit('CHANGEPAW', 1)">马上登录</span>
      </div>
      <i class="el-icon-close" @click="$emit('close', false)"></i>
    </div>

    <!-- 忘记密码 -->
    <template v-if="pasw_show==3">
      <div class="login-content">
        <div class="login-content-title">重置密码</div>
        <el-input
          v-model="abrufen.account"
          placeholder="请输入您的身份证号"
        ></el-input>
        <el-input
          v-model="abrufen.password"
          placeholder="请输入您的姓名"
        ></el-input>
        <div class="code">
          <el-input
            v-model="abrufen_code"
            placeholder="请输入验证码"
            @input="changeStep"
          ></el-input>
          <VerificationCode @changeCode="changeCode"></VerificationCode>
        </div>

        <el-button type="primary" @click="nextStep" :disabled="step_disabled"
          >下一步</el-button
        >

        <i class="el-icon-close" @click="$emit('close', false)"></i>
      </div>
    </template>

    <template v-if="find_box_tow">
      <div class="login-content">
        <div class="login-content-title">重置密码</div>
        <el-input
          v-model="find_data.account"
          placeholder="请输入手机号"
          @blur="checkPhoneNumber"
        ></el-input>
        <div class="get_code">
          <el-input
            v-model="find_data.code"
            placeholder="请输入短信验证码"
          ></el-input>
          <span v-if="!num_show" @click="btnCode" class="code">获取验证码</span>
          <span class="countdown" v-else>{{ num }}</span>
        </div>

        <el-input
          type="password"
          v-model="find_data.password"
          placeholder="请输入新密码"
        ></el-input>

        <el-input
          type="password"
          v-model="cfm_pawd"
          placeholder="请输入确认密码"
        ></el-input>

        <el-button type="primary" @click="confirm">确认</el-button>

        <i class="el-icon-close" @click="$emit('close', false)"></i>
      </div>
    </template>
    <template v-if="return_login">
      <div class="login-content">
        <div class="login-content-title">重置密码</div>
        <div class="icon-success">
          <i class="el-icon-success"></i>
          <span class="icon-success-text">重置成功</span>
        </div>

        <el-button
          type="primary"
          @click="
            (return_login = false), (find_box_tow = false), (find_box = false),$store.commit('CHANGEPAW', 1)

          "
          >返回登录</el-button
        >

        <i class="el-icon-close" @click="$emit('close', false)"></i>
      </div>
    </template>
  </div>
</template>
<script>
import {
  login,
  register,
  resetpwd,
  check_user,
  send,
  get_login_img,
} from "@/api/index";
import local from "@/utils/local";
import VerificationCode from "./com/VerificationCode.vue";
import dragVerifyImg from "vue-drag-verify-img";
export default {
  props: {
    login__add_register: {
      type: Number,
      default: 1,
    },
    imgsrc: {
      default: "https://t7.baidu.com/it/u=2763645735,2016465681&fm=193&f=GIF",
    },
  },
  components: {
    VerificationCode,
    dragVerifyImg,
  },
  data() {
    return {
     
      // 登录
      // imgsrc:"",
      login_data: {
        account: "",
        password: "",
        login_type: "1",
      },
      login_disabled: true, //登录按钮禁用\
      code_show: false,
      isPassing: false,

      // 注册
      register_data: {
        username: "",
        nickname: "",
        password: "",
        mobile: "",
        company_name:""
      },

      two_password: "", //确认密码
      router_url: "",
      // 忘记密码
      abrufen: {
        account: "",
        password: "",
      },
      step_disabled: true,
      code: "",
      abrufen_code: "", //验证码
      find_box: false, //找回密码
      // 确认重置密码
      find_data: {
        account: "",
        code: "",
        password: "",
      },
      cfm_pawd: "", //确认密码
      num: 60, //验证码倒计时
      num_show: false, //显示倒计时
      find_box_tow: false, //找回密码2
      return_login: false, //返回登录
      find_data_code_show: true,
      
    };
  },
  created() {
    this.router_url = this.$route.path;
    
  },
  computed:{
    pasw_show(){
      return this.$store.state.pasw_show;
    }
   
  },

  methods: {
     //刷新图片
     refresh(){
this.$emit("refresh",false)
    },
    //登录输入框失去焦点事件
    blur() {},
    //登录失焦验证输入框不为空
    accountChange() {
      this.loginChange();
    },
    loginChange() {
      if (this.login_data.account == "" || this.login_data.password == "") {
        console.log(111);
        this.login_disabled = true;
      }
      if (this.login_data.account !== "" && this.login_data.password !== "") {
        console.log(222);
        this.login_disabled = false;
      }
    },

    // 登录滑块验证通过登录
    passcallback() {
      this.isPassing = false;
      this.$refs.dragVerify.reset();

      login(this.login_data).then((res) => {
        this.code_show = false;
        if (res.data.code == 1) {
          this.$router.push("/index");
          local.set("HSTOKEN", res.data.data.userinfo.token);
          local.set("HSUSER", res.data.data.userinfo);
          this.$emit("login", false);
          this.$emit("getAvatar", res.data.data.userinfo.avatar);
          this.$store.commit("LOGIN", true);
          this.$store.commit("LOSE", false);
          this.$utils.prompt("success", res.data.msg);
          location.reload()//强制刷新页面
        } else {
          this.$utils.prompt("error", res.data.msg);
        }
      });
    },
    // 登录
    loginBtn() {
      this.code_show = true;
      if (this.login_data.account === "") {
        this.$utils.prompt("error", "请输入您的身份证号/手机号");
      } else if (this.login_data.password === "") {
        this.$utils.prompt("error", "请输入您的密码");
      }
    },
    //注册
    registerBtn() {
      if (this.register_data.nickname == "") {
        this.$utils.prompt("error", "请输入您的姓名");
        return;
      } else if (this.register_data.username == "") {
        this.$utils.prompt("error", "请输入您的身份证号");
        return;
      } else if (this.register_data.mobile == "") {
        this.$utils.prompt("error", "请输入您的手机号");
        return;
      } else if (this.register_data.password == "") {
        this.$utils.prompt("error", "请输入您的密码");
        return;
      } else if (this.two_password == "") {
        this.$utils.prompt("error", "请再次输入您的密码");
        return;
      } else if (this.register_data.password !== this.two_password) {
        this.$utils.prompt("error", "两次密码不一致");
        return;
      } else {
        register(this.register_data).then((res) => {
          console.log(res);
          if (res.data.code == 1) {
            
            this.$store.commit("CHANGEPAW", true);
            this.$utils.prompt("success", res.data.msg);
          } else if (res.data.code == 0) {
            this.$utils.prompt("error", res.data.msg);
          }
        });
      }
    },

    // 获取验证码
    changeCode(val) {
      this.code = val;
      console.log(this.code);
    },
    changeStep() {
      if (
        this.abrufen.account !== "" &&
        this.abrufen.password !== "" &&
        this.abrufen_code !== ""
      ) {
        this.step_disabled = false;
      }
    },
    //下一步
    nextStep() {
      if (
        this.abrufen.account !== "" &&
        this.abrufen.password !== "" &&
        this.abrufen_code !== ""
      ) {
        if (this.code == this.abrufen_code) {
          check_user({
            username: this.abrufen.account,
            nickname: this.abrufen.password,
          }).then((res) => {
            if (res.data.code == 1) {
              (this.find_box_tow = true), (this.find_box = false);
            } else {
              this.$message.error(res.data.msg);
            }
          });
        } else {
          this.$message.error("验证码错误");
        }
      }
    },
    // 忘记密码手机号验证
    checkPhoneNumber() {
      const mobileReg =
        /^(13[^4]|14[579]|15[^4\D]|16[6]|17[^49\D]|18\d|19[89])\d{8}$/;
      if (!mobileReg.test(this.find_data.account)) {
        this.$utils.prompt("error", "错误手机号码");
        this.find_data.account = "";
        this.find_data_code_show = false;
      }
    },

    // 获取手机验证码
    btnCode() {
      if (this.find_data_code_show) {
        send({ mobile: this.find_data.account, event: "resetpwd" }).then(
          (res) => {
            console.log(res);
            this.num_show = true;
            let time = setInterval(() => {
              this.num -= 1;
              if (this.num == 0) {
                this.num = 60;
                this.num_show = false;
                clearInterval(time);
              }
            }, 1000);
          }
        );
      } else {
        this.$utils.prompt("error", "请输入正确的手机号码");
      }
    },
    //确认重置密码
    confirm() {
      if (
        this.find_data.account !== "" &&
        this.find_data.password !== "" &&
        this.find_data.code !== "" &&
        this.cfm_pawd !== ""
      ) {
        if (this.find_data.password == this.cfm_pawd) {
          resetpwd({
            mobile: this.find_data.account,
            newpassword: this.find_data.password,
            captcha: this.find_data.code,
          }).then((res) => {
            if (res.data.code == 1) {
              this.find_box_tow = false;
              this.find_box = false;
              this.return_login = true;
            } else {
              this.$message.error(res.data.msg);
            }
          });
        } else {
          this.$message.error("两次密码不一致");
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.code-box {
 
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 9;
 border-radius: 8px;
 overflow: hidden;
 background: #fff;
 padding: 0 30px;
 
 ::v-deep img{
  height: 232px !important;
 }

 .title{
  height: 50px;
  line-height: 50px;
 padding: 0 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  font-weight: 700;
 }
 .footer-refresh{
  padding-right: 10px;
  height: 60px;
 display: flex;
 align-items: center;
 justify-content: flex-end;
  font-size: 24px;
  .el-icon-refresh-right{
    font-size: 26px;
    margin-right: 10px;
  }
 }
 ::v-deep .drag_verify {
  border: 1px solid #ccc;
  margin-top: 10px;
 }
 
}
::v-deep .el-input__inner {
  background: #f0f4f7;
  border: 0;
  margin-bottom: 22px;
}
::v-deep .el-button {
  width: 100%;
  margin-bottom: 22px;
  margin-top: 8px;
}
.login {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  background: rgba(0, 0, 0, 0.5);
  z-index: 9999999999;

  .login-content,
  .register-content {
    width: 435px;

    background: #ffffff;
    border-radius: 10px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 20px 52px 46px 52px;
    .password-input {
      position: relative;
      .password-tishi {
        color: red;
        font-size: 12px;
        position: absolute;
        top: 42px;
        left: 0;
      }
    }
    .login-content-title {
      text-align: center;
      margin-bottom: 34px;
      font-size: 18px;
    }
    .login-content-footer,
    .register-content-footer {
      text-align: right;
      font-size: 13px;
      .registrieren {
        color: #1769fe;
        cursor: pointer;
      }
    }
    .login-password {
      ::v-deep .el-input__inner {
        padding-right: 70px;
      }
    }
  }
  .forgot-password {
    cursor: pointer;
    z-index: 1;
    position: absolute;
    top: 150px;
    right: 54px;
    color: #1769fe;
    font-size: 13px;
  }
}
.el-icon-close {
  position: absolute;
  top: 22px;
  right: 30px;
  font-size: 18px;
  font-weight: 700;
  cursor: pointer;
}

.code {
  display: flex;
  .el-input {
    margin-right: 10px;
  }
}
.icon-success {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .icon-success-text {
    font-size: 30px;
    margin-bottom: 30px;
  }
}
.el-icon-success {
  font-size: 80px;
  color: #74c041;
  padding: 30px 0;
}
.get_code {
  position: relative;
  .countdown {
    position: absolute;
    color: #999999;
    top: 12px;
    right: 40px;
  }
  .code {
    position: absolute;
    color: #1769fe;
    font-size: 13px;
    top: 12px;
    right: 10px;
    cursor: pointer;
  }
}
</style>