import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import moment from 'moment'
import "./utils/rem.js"
import 'nprogress/nprogress.css';
//引入公共样式
import "./assets/css/common.css"
//引入图标样式
import "@/assets/font/iconfont.css"
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
//引入video.js
import 'video.js/dist/video-js.css'; // 引入video.js的css
import hls from "videojs-contrib-hls"; // 播放hls流需要的插件
import Videojs from "video.js"; 
window.videojs = Videojs;
require("../node_modules/video.js/dist/lang/zh-CN.js");
//引入工具函数
import utils from "@/utils/werkzeug.js"
Vue.prototype.$utils=utils
//引入websocket
import wsConnect from "@/utils/websocket";
Vue.prototype.$ws = wsConnect
// title和meta标签
import MetaInfo from 'vue-meta-info'
Vue.use(MetaInfo)



Vue.use(hls);
// 关闭dialog滚动条
ElementUI.Dialog.props.lockScroll.default = false
Vue.prototype.$bus = new Vue()

import debounce from "@/utils/throttle.js"
Vue.prototype.$debounce = debounce


Vue.use(ElementUI)
Vue.config.productionTip = false
Vue.prototype.$moment = moment


new Vue({
  router,
  store,
  render: h => h(App),
  mounted () {
    document.dispatchEvent(new Event('render-event'))
  }
}).$mount('#app')
