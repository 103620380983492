import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    bool:false,
    lose_efficacy:false,
    permissions: false,
    url: '',
    //ws参数
    path: '',
    ws: null,//建立的连接
    lockReconnect: false,//是否真正建立连接
    timeout: 20*1000,//20秒一次心跳
    timeoutObj: null,//心跳心跳倒计时
    serverTimeoutObj: null,//心跳倒计时
    timeoutnum: null,//断开 重连倒计时
    msg:{},//websocket接受到得消息
  login_show:false,//是否是登录状态
  pasw_show:1

  },
  getters: {
  },
  mutations: {
    CHANGEPAW(state,val){
      state.pasw_show=val
    },
    LOGIN(state,val){
      state.login_show=val
    },
    CHANGE(state,val){
      state.bool=val
     
    },
   
    LOSE(state,val){
      state.lose_efficacy=val
    },
    SOCK_MSG(state,val){
      
      state.msg=val
      console.log(  "vuex收到得消息",val);
    }
  },
  actions: {
  },
  modules: {
  }
})
