import store from "@/store";

function initWebpack() {
  
    var wsurl = 'wss://api.hszhixiao.com:10002'
    // var wsurl = 'ws://192.168.2.13:10002'
    store.state.ws = new WebSocket(wsurl);
    store.state.ws.onopen = onopen;
    store.state.ws.onmessage = onmessage;
    store.state.ws.onclose = onclose;
    store.state.ws.onerror = onerror;
}
function onopen() {
    console.log("学员端连接websocket");
    var params = '{"reqtype":"Query","action":"allexts"}'
    store.state.ws.send(params)
    // start();
}
function reconnect() {//重新连接
    var that = store.state;
    if (that.lockReconnect) {
        return;
    }
    that.lockReconnect = true;
    //没连接上会一直重连，设置延迟避免请求过多
    that.timeoutnum && clearTimeout(that.timeoutnum);
    that.timeoutnum = setTimeout(function () {
        
        //新连接
        initWebpack();
        that.lockReconnect = false;
        that.isFirstGet = true
    }, 5000);
}
function reset() {//重置心跳
    var that = store.state;
    //清除时间
    clearTimeout(that.timeoutObj);
    clearTimeout(that.serverTimeoutObj);
    //重启心跳
    start();
}
function start() { //开启心跳
  
    var self = store.state;
    self.timeoutObj && clearTimeout(self.timeoutObj);
    self.serverTimeoutObj && clearTimeout(self.serverTimeoutObj);
    self.timeoutObj = setTimeout(function () {
        //这里发送一个心跳，后端收到后，返回一个心跳消息，
        console.log(self.ws.readyState);
        if (self.ws.readyState === 1) {//如果连接正常
            console.log('学员端开启心跳');
            self.ws.send( JSON.stringify("学员端砰砰")); //心跳包格式需要自己确定

        } else {//否则重连

            reconnect();
        }
        // self.serverTimeoutObj = setTimeout(function () {
        //     //超时关闭
        //     // self.ws.close();
        //     console.log("超时关闭");
        //     reconnect()
        // }, self.timeout);
    }, self.timeout)
}
function onmessage(e) {


 console.log(e);
    //处理数据的地方
    if (e.data) {  // 接收到消息
        // 自定义全局监听事件
        
        window.dispatchEvent(new CustomEvent('onmessageWS', {
          detail: {
            data: e.data
          }
        }))
      }
    // store.commit('SOCK_MSG',JSON.parse(e.data))
    // reset();
}
function onclose(e) {
    console.log("关闭连接");
    reconnect();

}
function onerror(e) {
    console.log("连接失败");
    //重连
    reconnect();
}


export default {
    initWebpack,
    onmessage,
    onclose,
    onopen,
    onerror
}
