import Vue from 'vue'
import VueRouter from 'vue-router'
import { scRouter } from "./sc-router"
import { yzqRouter } from "./yzq-router"
import NProgress from 'nprogress';
import { createLogger } from 'vuex';

NProgress.configure({
  showSpinner: false
});
Vue.use(VueRouter)
const routers = [

  { name:"item",
    path: "/lehrplanDetails/:id", component: () => import("@/components/page/lehrplanDetails.vue"),
    meta: {
      alive: true
    }
  },
  {
    path: "/lehrolanStudie", component: () => import("@/components/page/lehrolanStudie.vue"),
    meta: {
      alive: true
    }
  },
  {
    path: "/white/list", component: () => import("@/views/white-list/index.vue"),
    meta: {
      alive: true
    }
  },
  {
    path: "/playback", component: () => import("@/views/allLiveStreaming/details/playback")
  },
 
  
]



const routerPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return routerPush.call(this, location).catch(error => error)
}


const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [...routers, ...scRouter, ...yzqRouter]
})
router.beforeEach((to, from, next) => {
  //加载进度条
  NProgress.start();

  if (to.meta.title) {
    document.title = to.meta.title
  }
  let add= document.querySelectorAll('meta')
  console.log(add);
  add.forEach(v=>{
    if(v.name==='baidu-site-verification'){
      v.content=to.meta.title
      console.log( v.content);
    }
  })

  next();
});
router.afterEach(() => {
  NProgress.done();
});
export default router
