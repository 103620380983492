<template>
  <!-- 顶部 -->
  <div class="header">
    <div class="header-content">
      <div>
        <div></div>
        <img class="login-img" :src="logo" @click="goHome" alt="logo" />

      </div>
      <nav class="header-content-title">
        <router-link
          :class="{ aktivie: aktivie_index == item.path }"
          class="header-content-title-item"
         :to="item.path"
          v-for="(item, index) in nav_list"
          :key="index"
          >{{ item.title }}</router-link
        >
      </nav>
      <div class="header-content-right" v-if="!ob_login">
        <span @click="$store.commit('CHANGE', true), $store.commit('CHANGEPAW', 1)"
          >登录</span
        >
        <span @click="$store.commit('CHANGE', true),$store.commit('CHANGEPAW',2 )"
          >注册</span
        >
      </div>

      <el-dropdown v-else class="header-content-icon c-row">
        <img class="avatar-img" :src="circleUrl" alt="用户" />
        <!-- <el-avatar  :src="circleUrl" @error="errorHandler"></el-avatar> -->
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item disabled>{{ name || "" }}</el-dropdown-item>
          <el-dropdown-item @click.native="$router.push('/personalCenter')"
            >个人中心</el-dropdown-item
          >
          <el-dropdown-item @click.native="loginOut">退出登录</el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
<div v-if="login_register">
  <Login
  :imgsrc="imgsrc"
        @login="login"
        :login__add_register="login__add_register"
        @go="go"
        @close="close"
        @getAvatar="getAvatar"
        @refresh="refresh"
      ></Login>
</div>
      
    </div>
    <!-- 退出登录弹框 -->
  </div>
</template>

<script>
import local from "@/utils/local";
import Login from "@/components/login/index.vue";
import { loginout,get_login_img } from "@/api/index";
export default {
  components: {
    Login,
  },
 
  data() {
    return {
      imgsrc:"",
      name: local.get("HSUSER") ? local.get("HSUSER").nickname : "张三",
      circleUrl: "",
      logo: require("../../assets/img/logo.png"),
      aktivie_index: "", //激活索引
     
      // login_register:this.$store.state.bool, //登录和注册弹框
      login__add_register: 1, //判断点击登录还是注册1登录2注册
      path: "", //当前页得路由地址
      nav_list: [
        {
          name: 0,
          title: "首页",
          path: "/index",
        },
        {
          name: 1,
          title: "全部课程",
          path: "/allLehrplan",
        },
        // {
        //   name: 2,
        //   title: "实时课堂",
        //   path: "/allLiveStreaming",
        // },
        // {
        //   name: 3,
        //   title: "合作院校",
        //   path: "/partnerSchools",
        // },
        {
          name: 4,
          title: "在线练习",
          path: "/mockexam",
        },
        {
          name: 5,
          title: "关于我们",
          path: "/aboutUs",
        },
        {
          name: 6,
          title: "企业服务",
          path: "/enterpriseCulture",
        },
        
       
      ], //导航头
    };
  },
  created() {
    this.getcodeImg()
    if (local.get("HSTOKEN")) {
      
    }
    
    if (local.get("HSUSER")) {
     
        this.circleUrl = local.get("HSUSER").avatar;
     
    }
  },
  mounted(){
  },

  computed: {
    login_register() {
      return this.$store.state.bool;
    },
    ob_login(){
      return this.$store.state.login_show;
    }
  },
  methods: {
    refresh(){
this.getcodeImg()
    },
     // 获取滑块背景图
     getcodeImg() {
      get_login_img().then((res) => {
        console.log(88, res);
        this.imgsrc = res.data.data.image_url;
      });
    },
    goHome(){
      if(this.$route.path!=="/"){
        this.$router.push('/')

      }
    },
    //点击登录获取头像
    getAvatar(val){
      this.circleUrl=val
    },
    errorHandler() {
      console.log(111);
      // console.log("图片加载失败");
    },
    goItem(val, index) {
      this.$router.push(
        {
          path: val.path,
        },
        () => {}
      );
    },
    //点击登录按钮
    login(val) {
      
      this.$store.commit("CHANGE", val);
      this.$emit("loginOk",null)
    },
    //点击关闭图标
    close(val) {
      this.$store.commit("CHANGE", val);
      this.$store.commit('LOSE', false)
      console.log(8989,this.$route.path);
      if (this.$route.path !== "/"||this.$route.path !== "/index") {
        this.$router.push("/index");
      }
    },
    go(val) {
      this.login__add_register = val;
    },
    //退出登录
    loginOut() {
      this.$confirm("是否确定退出登录, ", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          loginout().then((res) => {
            if (res.data.code == 1) {
              this.$store.commit('LOGIN', false)
              local.clear();
              
           
              if(this.$route.path!=="/")
              this.$router.push("/");
            }
          });
        })
        .catch(() => {});
    },
  },
  watch: {
    $route: {
      handler: function (val, oldVal) {
        this.aktivie_index = val.path;
      },
      // 深度观察监听
      deep: true,
    },
    
  },
};
</script>

<style  scoped lang="scss">
.login-img{
  height: 60px;
  width: 60px;
}
a{
  text-decoration: none;
  color: #000;
}
.header {
  height: 90px;
  .header-content {
    position: relative;
    width: 1200px;
    height: 90px;
    margin: 0 auto;
    display: flex;
    align-items: center;
    .header-content-title {
      margin-left: 40px;
      .header-content-title-item {
        margin-right: 32px;
        padding-bottom: 10px;
        cursor: pointer;
      }
    }
    .header-content-right {
      cursor: pointer;
      position: absolute;
      right: 0;
      padding: 9px 18px;
      width: 135px;
      height: 40px;
      background: #18212d;
      display: flex;
      align-items: center;
      justify-content: space-between;
      color: #fff;
      border-radius: 5px;
    }
    .header-content-right:after {
      content: "";
      height: 21px;
      width: 1px;
      background: #fff;
      position: absolute;
      left: 67px;
    }
    .header-content-icon {
      position: absolute;
      right: 0;
      height: 50px;
      width: 50px;
      color: #1769fe;
      font-size: 38px;
      background: #eaf4fe;
      border-radius: 50%;
      display: flex;
      .avatar-img {
        height: 50px;
        width: 50px;
        border-radius: 50%;
      }
    }
  }
}
.aktivie {
  border-bottom: 2px solid #1769fe;
  color: #1769fe;
}
</style>

