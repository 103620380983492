// axios封装-通用工具函数
// 引入axios
import axios from 'axios'
import local from './local';
import router from '@/router/index'
import { Message } from 'element-ui'
import store from '@/store';

// 服务器地址 写上以后 所有的请求 会自动在前面拼接这个地址
console.log(666,process.env.VUE_APP_LOGIN_URL);
axios.defaults.baseURL =  process.env.VUE_APP_LOGIN_URL;
axios.defaults.timeout = 20000;  // 超时

// 添加请求拦截器
axios.interceptors.request.use(function (config) {
  //     // 在发送请求之前做些什么

  let token = local.get("HSTOKEN")
  if (token) {
    //    请求之前携带token，用户认证
    config.headers.token = token
  }

  return config;
}, function (error) {
console.log("// 对请求错误做些什么",error);

  // 对请求错误做些什么
  return Promise.reject(error);
});

// 添加响应拦截器
axios.interceptors.response.use(function (response) {
   // 对响应数据做点什么
  return response;
}, function (error) {

console.log("对响应数据做点什么",error);
console.log(6969,error.response.data.code);
  if (error.response.data.code == 401) {
    //  router.push("/")
    setTimeout(() => {
      // store.commit('CHANGE', true)
      store.commit('LOSE', true)
    }, 1000)

  } else {
    
      router.push("/white/list")
   
   
  }
  // console.log(error);
  // 对响应错误做点什么
  return Promise.reject(error);



});
export default axios